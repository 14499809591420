import React, { Component } from "react"
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import FixedMenu from "../components/FixedMenu" 
import SEO from "../components/seo"


import SiteContext from "../context/SiteContext"

class Literature extends Component {

    static contextType = SiteContext;

    constructor(props) {
        super(props)
        this.state = {
            site: '',
        }
    }

    componentDidMount() {
        let site = 'senergy'
        
        this.context.changeSite(site)
        this.setState({ site })
    }

    render() {

        // const literature = this.props.pageContext.literatureData.data.allRestApiLiterature.nodes

        const { literatureData, technicalBulletinCategoryData, productCategoryData, systemFamilyData, codeReportsCategoryData } = this.props.pageContext
        const literatureItems = literatureData.data.craft.entries
        
        const productCategories = productCategoryData.data.craft.categories
        const systemFamilies = systemFamilyData.data.craft.categories
        const technicalBulletinCategories = technicalBulletinCategoryData.data.craft.categories
        const codeReportsCategories = codeReportsCategoryData.data.craft.categories

        const sortedProductCategories = productCategories.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
        const sortedSystemFamilies = systemFamilies.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
        const sortedTechnicalBulletinCategories = technicalBulletinCategories.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
        const sortedCodeReportsCategories = codeReportsCategories.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));

        const brochures = literatureItems.filter(item => item.sectionHandle == 'brochures')
        const codeReports = literatureItems.filter(item => item.sectionHandle == 'codeReports')
        const productBulletins = literatureItems.filter(item => item.sectionHandle == 'productBulletins')
        const specifications = literatureItems.filter(item => item.sectionHandle == 'specifications')
        const systemOverviews = literatureItems.filter(item => item.sectionHandle == 'systemOverviews')
        const safetyDataSheets = literatureItems.filter(item => item.sectionHandle == 'safetyDataSheets')
        const technicalBulletins = literatureItems.filter(item => item.sectionHandle == 'technicalBulletins')
        const details = literatureItems.filter(item => item.sectionHandle == 'details')
        const twoDpdfs = literatureItems.filter(item => item.sectionHandle == 'twoDpdfs')

        const systemFamiliesWithOverviews = []
        const systemFamiliesWithSpecifications = []
        const productCategoriesWithResources = []
        const productCategoriesWithSDS = []
        const technicalBulletinCategoriesWithResources = []
        const codeReportsCategoriesWithResources = []

        sortedSystemFamilies.map(fam => {
            systemOverviews.map(item => {
                if (item.systemFamily.filter(category => category.slug === fam.slug).length > 0) {
                    systemFamiliesWithOverviews.push(fam)
                }
            })
        })

        sortedSystemFamilies.map(fam => {
            specifications.map(item => {
                if (item.systemFamily.filter(category => category.slug === fam.slug).length > 0) {
                    systemFamiliesWithSpecifications.push(fam)
                }
            })
        })

        sortedProductCategories.map(cat => {
            productBulletins.map(item => {
                if (item.productCategory.filter(category => category.slug === cat.slug).length > 0) {
                    productCategoriesWithResources.push(cat)
                }
            })
        })
        
        sortedProductCategories.map(cat => {
            safetyDataSheets.map(item => {
                if (item.productCategory.filter(category => category.slug === cat.slug).length > 0) {
                    productCategoriesWithSDS.push(cat)
                }
            })
        })

        sortedTechnicalBulletinCategories.map(cat => {
            technicalBulletins.map(item => {
                if (item.technicalBulletinCategories.filter(category => category.slug === cat.slug).length > 0) {
                    technicalBulletinCategoriesWithResources.push(cat)
                }
            })
        })

        sortedCodeReportsCategories.map(cat => {
            codeReports.map(item => {
                if (item.codeReportsCategories.filter(category => category.slug === cat.slug).length > 0) {
                    codeReportsCategoriesWithResources.push(cat)
                }
            })
        })

        const uniqueSystemFamiliesWithOverviews = Array.from(new Set(systemFamiliesWithOverviews.map(a => a.id)))
            .map(id => {
                return systemFamiliesWithOverviews.find(a => a.id === id)
            })

        const uniqueSystemFamiliesWithSpecifications = Array.from(new Set(systemFamiliesWithSpecifications.map(a => a.id)))
            .map(id => {
                return systemFamiliesWithSpecifications.find(a => a.id === id)
            })

        const uniqueProductCategoriesWithResources = Array.from(new Set(productCategoriesWithResources.map(a => a.id)))
            .map(id => {
                return productCategoriesWithResources.find(a => a.id === id)
            })

        const uniqueProductCategoriesWithSDS = Array.from(new Set(productCategoriesWithSDS.map(a => a.id)))
            .map(id => {
                return productCategoriesWithSDS.find(a => a.id === id)
            })

        const uniqueTechnicalBulletinCategoriesWithResources = Array.from(new Set(technicalBulletinCategoriesWithResources.map(a => a.id)))
            .map(id => {
                return technicalBulletinCategoriesWithResources.find(a => a.id === id)
            })

        const uniqueCodeReportsCategoriesWithResources = Array.from(new Set(codeReportsCategoriesWithResources.map(a => a.id)))
            .map(id => {
                return codeReportsCategoriesWithResources.find(a => a.id === id)
            })

        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <>
                        <SEO title="Literature" />
                        <div className="wrapper literature-page app-header-style-text">
                            <div className="content-wrapper">
                                <div className="app-header">
                                    <div className="app-header-content">
                                        <h1 className="app-header__title">Literature</h1> 
                                        <div className="spacer"></div>
                                    </div>
                                </div>    
                                <div className="literature-wrapper">                      
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                        <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="brochures"/></div>
                                        <Typography variant="h3"> Brochures </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <ul>
                                            {brochures.map(item => {
                                                return (
                                                    <li>
                                                        <a target="_blank" className="link-row link-row__multi-line senergy" href={`${item.externalLink ? item.externalLink : item.filepath}`}>
                                                            <span>
                                                                <span className="title">{item.title}</span>
                                                            </span>
                                                            <span className="right icon senergy"><IcomoonReact iconSet={iconSet} size={14} icon="arrow-right2" /></span>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <span className="spacer"></span>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                        <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="sds" /></div>
                                        <Typography variant="h3"> Safety Data Sheets </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {uniqueProductCategoriesWithSDS.map(prodCat => {
                                            return (
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary expandIcon={< ExpandMoreIcon color="primary" />} >
                                                        <Typography variant="h4">{prodCat.title}</Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <ul>
                                                            {safetyDataSheets.map(item => {
                                                                if (item.productCategory.filter(category => category.slug === prodCat.slug).length > 0) {
                                                                    return (
                                                                        <li>
                                                                            <a target="_blank" className="link-row link-row__multi-line senergy" href={`${item.externalLink ? item.externalLink : item.filepath}`}>
                                                                                <span>
                                                                                    <span className="title">{item.title}</span>
                                                                                </span>
                                                                                <span className="right icon senergy"><IcomoonReact iconSet={iconSet} size={14} icon="arrow-right2" /></span>
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                }
                                                            })}
                                                        </ul>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            )
                                        })}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <span className="spacer"></span>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                        <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="code-reports" /></div>
                                        <Typography variant="h3">Code Reports</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {uniqueCodeReportsCategoriesWithResources.map(cat => {
                                            return (
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                                        <Typography variant="h4">{cat.title}</Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <ul className="product-list">
                                                            {codeReports.map(item => {
                                                                if (item.codeReportsCategories.filter(category => category.slug === cat.slug).length > 0) {
                                                                    return (
                                                                        <li>
                                                                            <a className="link-row link-row__multi-line senergy" href={`${item.externalLink ? item.externalLink : item.filepath}`} target="_blank" rel="nofollow noopener noreferrer">
                                                                                <span>
                                                                                    <span className="title">{item.title}</span>
                                                                                </span>
                                                                                <span className="right icon senergy"><IcomoonReact iconSet={iconSet} size={14} icon="arrow-right2" /></span>
                                                                            </a>
                                                                            <p className="download-description">{item.description}</p>
                                                                        </li>
                                                                    )
                                                                }
                                                            })}
                                                        </ul>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            )
                                        })}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                <span className="spacer"></span>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                        <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="specifications"/></div>
                                        <Typography variant="h3">Specifications</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {uniqueSystemFamiliesWithSpecifications.map(fam => {
                                            return (
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary expandIcon={< ExpandMoreIcon color="primary" />} >
                                                        <Typography variant="h4">{fam.title}</Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <ul>
                                                            {specifications.map(item => {
                                                                if (item.systemFamily.filter(category => category.slug === fam.slug).length > 0) {
                                                                    return (
                                                                        <li>
                                                                            <a target="_blank" className="link-row link-row__multi-line senergy" href={`${item.externalLink ? item.externalLink : item.filepath}`}>
                                                                                <span>
                                                                                    <span className="title">{item.title}</span>
                                                                                </span>
                                                                                <span className="right icon senergy"><IcomoonReact iconSet={iconSet} size={14} icon="arrow-right2" /></span>
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                }
                                                            })}
                                                        </ul>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            )
                                        })}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <span className="spacer"></span>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                        <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="product-bulletins"/></div>
                                        <Typography variant="h3">Product Bulletins</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {uniqueProductCategoriesWithResources.map(prodCat => {
                                            return (
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary expandIcon={< ExpandMoreIcon color="primary" />} >
                                                        <Typography variant="h4">{prodCat.title}</Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <ul>
                                                            {productBulletins.map(item => {
                                                                if (item.productCategory.filter(category => category.slug === prodCat.slug).length > 0) {
                                                                    return (
                                                                        <li>
                                                                            <a target="_blank" className="link-row link-row__multi-line senergy" href={`${item.externalLink ? item.externalLink : item.filepath}`}>
                                                                                <span>
                                                                                    <span className="title">{item.title}</span>
                                                                                </span>
                                                                                <span className="right icon senergy"><IcomoonReact iconSet={iconSet} size={14} icon="arrow-right2" /></span>
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                }
                                                            })}
                                                        </ul>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            )
                                        })}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <span className="spacer"></span>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                        <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="system-overviews"/></div>
                                        <Typography variant="h3">System Overviews</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {uniqueSystemFamiliesWithOverviews.map(fam => {
                                            return (
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary expandIcon={< ExpandMoreIcon color="primary" />} >
                                                        <Typography variant="h4">{fam.title}</Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <ul>
                                                            {systemOverviews.map(item => {
                                                                if (item.systemFamily.filter(category => category.slug === fam.slug).length > 0) {
                                                                    return (
                                                                        <li>
                                                                            <a target="_blank" className="link-row link-row__multi-line senergy" href={`${item.externalLink ? item.externalLink : item.filepath}`}>
                                                                                <span>
                                                                                    <span className="title">{item.title}</span>
                                                                                </span>
                                                                                <span className="right icon senergy"><IcomoonReact iconSet={iconSet} size={14} icon="arrow-right2" /></span>
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                }
                                                            })}
                                                        </ul>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            )
                                        })}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <span className="spacer"></span>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                        <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="technical-bulletins"/></div>
                                        <Typography variant="h3">Technical Bulletins</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {uniqueTechnicalBulletinCategoriesWithResources.map(cat => {
                                            if (cat.level === 1) {
                                                return (
                                                    <ExpansionPanel>
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                                            <Typography variant="h4">{cat.title}</Typography>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>
                                                            <ul className="product-list">
                                                                {technicalBulletins.map(item => {
                                                                    if (item.technicalBulletinCategories.filter(category => category.slug === cat.slug).length > 0) {
                                                                        return (
                                                                            <li>
                                                                                <a target="_blank" className="link-row link-row__multi-line senergy" href={`${item.externalLink ? item.externalLink : item.filepath}`}>
                                                                                    <span>
                                                                                        <span className="title">{item.title}</span>
                                                                                    </span>
                                                                                    <span className="right icon senergy"><IcomoonReact iconSet={iconSet} size={14} icon="arrow-right2" /></span>
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    }
                                                                })}
                                                            </ul>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                )
                                            }
                                        })}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <span className="spacer"></span>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />} >
                                    <div className="literature__icon"><IcomoonReact iconSet={iconSet} size={24} icon="details"/></div>
                                        <Typography variant="h3">Details</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <ul>
                                            {details.map(item => {
                                                return (
                                                    <li>
                                                        <a target="_blank" className="link-row link-row__multi-line senergy" href={`${item.externalLink ? item.externalLink : item.filepath}`}>
                                                            <span>
                                                                <span className="title">{item.title}</span>
                                                            </span>
                                                            <span className="right icon senergy"><IcomoonReact iconSet={iconSet} size={14} icon="arrow-right2" /></span>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                </div>  
                                <span className="spacer"></span>
                            </div>
                            <FixedMenu active="literature" site="senergy" /> 
                        </div>
                    </>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default Literature